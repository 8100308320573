import styled from "styled-components";

import Link from "@components/atoms/Link";

export const StyledLink = styled(Link)`
  transition: ${({ theme }) => theme.transition};
  padding: 1.5rem 0;
  border-bottom: 1px solid #dadada;

  :first-child {
    border-top: 1px solid #dadada;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.brand};
    fill: ${({ theme }) => theme.colors.brand};
    border-bottom-color: ${({ theme }) => theme.colors.brand};
  }
`;
