import TechnologyTile from "@components/molecules/TechnologyTile";
import { tryToReplaceDomain } from "@utils/tryToReplaceDomain";

import { TechnologyTileStoryblokProps } from "./types";

const IconLinkStoryblok: React.FC<TechnologyTileStoryblokProps> = ({
  blok
}) => {
  const {
    label,
    href: {
      cached_url: cachedUrl,
      url,
      rel,
      target,
      story: { full_slug: fullSlug = "" } = {}
    },
    icon: { filename }
  } = blok;

  const icon = tryToReplaceDomain(filename);
  const href = url || cachedUrl || fullSlug;
  const link = {
    href,
    rel,
    target
  };

  return <TechnologyTile {...{ label, link, icon }} />;
};

export default IconLinkStoryblok;
