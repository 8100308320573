import React from "react";
import { ReactSVG } from "react-svg";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";
import { FlexBox } from "@pagepro-monorepo/ui/lib/components/styles/Grid";

import { TechnologyTileProps } from "./types";
import { StyledLink } from "./styles";

const TechnologyTile: React.FC<TechnologyTileProps> = ({
  label,
  link,
  icon
}) => (
  <StyledLink {...link}>
    <FlexBox display="flex" gap="1rem" alignItems="center">
      <ReactSVG src={icon} />
      <Typography fontSize={["1rem", "1.5rem"]}>{label}</Typography>
    </FlexBox>
  </StyledLink>
);

export default TechnologyTile;
